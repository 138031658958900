/* eslint-disable */
import React, { useRef, useEffect, useState, useContext, memo } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { organizeStops, organizeStopsNew } from 'utils';
import { DEFAULT_LANG } from '@lux/spa/config';
import { JOURNEY_ROUTE } from '@lux/spa/apollo/queries';
import TicketLayout from './ticket-layout.jsx';
import s from './ticket.module.scss';

const ChangeTicket = ({
  t,
  i18n,
  isMob,
  passengers,
  ticketLegs,
  className,
  transfers,
  journeyId,
  originStopName,
  destinationStopName,
  availableRegularSeats,
  availableBusinessClassSeats,
  departureDateTimeTimeZone,
  arrivalDateTimeTimeZone,
  businessClassPrice,
  durationTravel,
  departureDateTime,
  arrivalDateTime,
  // campaignPrice,
  // businessCampaignPrice,
  availableCampaignSeats,
  availableBusinessCampaignSeats,
  history,
  hasEquipment,
  notifications,
  isModifiableJourney = false,
  ticket,
  toggleModalSelectPrice,
  isOpenSelectPrice,
}) => {
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);

  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const [showDetails, setShowDetails] = useState(false);
  const buttonGroupRef = useRef(null);

  const showTime = time => {
    return time
      .split('T')[1]
      .split(':')
      .slice(0, 2)
      .join(':');
  };

  const durationDays =
    durationTravel.includes('.') && durationTravel.split('.')[0];

  const duration = durationTravel
    .replace(`${durationDays}.`, '')
    .split(':')
    .slice(0, 2);

  const logoType =
    availableBusinessClassSeats || availableBusinessCampaignSeats
      ? 'logo-lounge'
      : 'logo-grey';

  let discountPrice = null;

  ticket.PriceClasses.forEach(i => {
    if (i.PriceClassName === 'Campaign') {
      discountPrice = true;
    }

    if (
      i.PriceClassName === 'BusinessCampaign' ||
      i.PriceClassName === 'CampaignBusiness'
    ) {
      discountPrice = true;
    }
  });

  const prices = [];
  ticket.PriceClasses.forEach(i => {
    prices.push(i.Price);
  });
  const minPrice = Math.min(...prices);

  let type = availableBusinessClassSeats ? 'lounge' : '';

  type =
    availableCampaignSeats || availableBusinessCampaignSeats ? 'promo' : type;

  const regularPrice = ticket.PriceClasses.find(
    item => item.PriceClassName === 'Regular'
  ).Price;

  ticket.PriceClasses.forEach(item => {
    if (
      item.PriceClassName === 'Campaign' &&
      (item.Price === regularPrice || regularPrice === 0)
    ) {
      type = 'regular';
    }
  });

  const [getJorney, { loading, data }] = useLazyQuery(JOURNEY_ROUTE, {
    fetchPolicy: 'network-only',
    variables: {
      journeyId,
      lang: language,
      includeIntermediateStops: true,
    },
    onCompleted: data => {
      if (!data?.journeyRoute?.Legs) {
        dispatch({
          type: 'UPDATE_CHANGE_TICKETS',
          payload: {
            isSearchOutOfDate: true,
          },
        });
        return;
      }

      const legs = data?.journeyRoute?.Legs || [];
      const stops = organizeStopsNew(legs, showTime);

      dispatch({
        type: 'UPDATE_CHANGE_TICKETS',
        payload: {
          stops,
        },
      });
    },
  });

  const legs = data?.journeyRoute?.Legs || [];
  const stops = organizeStopsNew(legs, showTime);
  const availableEquipment =
    hasEquipment &&
    ticketLegs
      ?.map(leg => leg.AvailableEquipment)
      ?.flat(1)
      ?.filter(
        (v, i, a) => a.findIndex(t => t.EquipmentType === v.EquipmentType) === i
      );

  const equipmentTypes = {
    ['COFFEE_MACHINE']: '/images/svg/hot-drinks.svg',
    ['WC']: '/images/svg/wc.svg',
    ['MULTIMEDIA_DISPLAY']: '/images/svg/multimedia.svg',
    ['INTERNET']: '/images/svg/wi-fi.svg',
    ['A_C']: '/images/svg/climate-control.svg',
    ['220V']: '/images/svg/220v-outlet.svg',
    ['COLD_DRINKS_MACHINE']: '/images/svg/free-bottle-of-water.svg',
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const classes = cn(s.ticket, type && s[type], className);

  const selectTicket = () => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        journey: ticket,
        priceClasses: ticket.PriceClasses,
      },
    });

    toggleModalSelectPrice();

    getJorney();
  };

  useEffect(() => {
    if (showDetails) {
      getJorney();
    }
  }, [showDetails]);

  useEffect(() => {
    const button = buttonGroupRef?.current;
    if (button) {
      button.addEventListener('mousemove', e => {
        button.style.setProperty(
          'background-position',
          `${e.clientX - 60}px ${e.clientY - 60}px`
        );
      });

      return () => {
        button.removeEventListener('mousemove', e => {
          button.style.setProperty(
            'background-position',
            `${e.clientX - 60}px ${e.clientY - 60}px`
          );
        });
      };
    }
  }, []);

  return (
    <TicketLayout
      classes={classes}
      type={type}
      isMob={isMob}
      showTime={showTime}
      departureDateTime={departureDateTime}
      arrivalDateTime={arrivalDateTime}
      logoType={logoType}
      originStopName={originStopName}
      destinationStopName={destinationStopName}
      durationDays={durationDays}
      duration={duration}
      transfers={transfers}
      toggleDetails={toggleDetails}
      showDetails={showDetails}
      loading={loading}
      stops={stops}
      availableEquipment={availableEquipment}
      equipmentTypes={equipmentTypes}
      availableRegularSeats={availableRegularSeats}
      availableBusinessClassSeats={availableBusinessClassSeats}
      isModifiableJourney={isModifiableJourney}
      buttonGroupRef={buttonGroupRef}
      selectTicket={selectTicket}
      minPrice={minPrice}
      symbol={symbol}
      discountPrice={discountPrice}
      passengers={passengers}
      notifications={notifications}
      legs={ticket.Legs}
    />
  );
};

export default withRouter(withTranslation()(memo(ChangeTicket)));
