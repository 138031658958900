import React, { useState } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { ORDER_WIDGET } from '@lux/spa/apollo/queries';
import { DEFAULT_LANG } from '@lux/spa/config';
import { removeCookie } from 'utils';
import {
  Link,
  Loading,
  LazyLoadImage,
  DiscardedBasket,
  DiscardedChangeTicketsSession,
} from '@lux/ui';
import s from './header-desktop.module.scss';

const DesktopHeader = ({
  t,
  i18n,
  langs,
  onChangeLang,
  user = {},
  isTicketPages,
  currencyName,
  travelInfoLink,
  dispatch,
  profileLoading,
  history,
  showFaq,
  location = { pathname: '' },
  showDiscardedBasketModal,
  showDiscardedChangeTicketSessionModal,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const logout = (redirect = true) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('token');
      removeCookie('token');

      dispatch({
        type: 'LOG_OUT',
      });

      if (redirect) {
        history.push(`/${DEFAULT_LANG === language ? '' : language}/`);
      }
    }
  };

  const { language } = i18n;
  return isTicketPages ? (
    <div className={cx(s.nav, isTicketPages && s.ticketPages)}>
      {isOpenModal && showDiscardedBasketModal && (
        <DiscardedBasket
          isOpen={isOpenModal}
          toggleModal={toggleModal}
          url="/profile/customer-program"
        />
      )}
      {isOpenModal && showDiscardedChangeTicketSessionModal && (
        <DiscardedChangeTicketsSession
          isOpen={isOpenModal}
          toggleModal={toggleModal}
          url="/profile/customer-program"
        />
      )}

      {profileLoading ? (
        <div className={s.section}>
          <div className={s.item}>
            <Loading />
          </div>
        </div>
      ) : user.firstName ? (
        <div className={s.section}>
          <div className={s.item}>
            <div className={s.user}>
              {user.avatar ? (
                <div
                  className={s.avatar}
                  style={{ backgroundImage: `url(${user.avatar})` }}
                />
              ) : (
                <div className={s.avatar}>{user.firstName.slice(0, 2)}1</div>
              )}
              <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
              <div className={s.wrapList}>
                <div className={s.list}>
                  <div className={s.wrap}>
                    <div className={s.triangle} />
                  </div>
                  {/* {showDiscardedBasketModal ||
                      showDiscardedChangeTicketSessionModal ? (
                        <>
                          {showDiscardedBasketModal && (
                            <div onClick={toggleModal} className={s.link}>
                              {t('account')}
                            </div>
                          )}
                          {showDiscardedChangeTicketSessionModal && (
                            <div onClick={toggleModal} className={s.link}>
                              {t('account')}
                            </div>
                          )}
                        </>
                      ) : (
                        <Link
                          to="/profile/customer-program/"
                          className={s.link}
                        >
                          {t('account')}
                        </Link>
                      )} */}

                  <div
                    className={cx(s.link, s.logout)}
                    children={t('logout')}
                    onClick={() => logout(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null
      // <div className={s.item} onClick={toggleLoginModal}>
      //   {t('login')}
      // </div>
      }

      <div className={s.section}>
        <div className={s.item}>
          {language}
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              {langs
                .filter(lang => lang !== language)
                .map(lang => (
                  <div
                    key={lang}
                    className={s.link}
                    onClick={() => onChangeLang(lang)}
                    children={lang}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div className={s.section}>
        <div className={s.item}>{currencyName}</div>
      </div> */}
    </div>
  ) : (
    <div className={s.nav}>
      {/* <Login isOpen={isOpenLoginModal} toggleModal={toggleLoginModal} /> */}
      <div className={s.section}>
        <div className={s.item}>
          <Link to={travelInfoLink} className={cx(s.link, s.important)}>
            {t('travel info')}
          </Link>
        </div>
        {/* <Query
          query={isStaff ? GET_ALL_PROMO_ADMIN : GET_ALL_PROMO}
          variables={{
            lang: language,
            country: region,
          }}
        >
          {({ data = {}, loading }) => {
            if (loading) return '';
            const promos = isStaff
              ? data?.adminPromoPages?.edges
              : data?.promoPages?.edges;
            return (
              (isStaff || Boolean(promos?.length)) && (
                <div className={s.item}>
                  {t('promo')}
                  <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
                  <div className={s.wrapList}>
                    <div className={s.list}>
                      <div className={s.wrap}>
                        <div className={s.triangle} />
                      </div>
                      {promos.map(route => {
                        const title = route.node.data.seo.title.value;
                        const { slug } = route.node.data;
                        return (
                          <Link
                            to={`/promo/${slug}/`}
                            className={s.link}
                            key={route.node.id}
                            children={title}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )
            );
          }}
        </Query> */}
        <div className={s.item}>
          {t('routes')}
          <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              <Query
                query={ORDER_WIDGET}
                variables={{
                  lang: language,
                  origin: 'popular-routes',
                }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;
                  const widget = data?.orderWidget;
                  const routes = widget?.routes || [];
                  return (
                    Boolean(routes?.length) &&
                    routes.map((route, key) => {
                      const departure = route.departure?.name;
                      const arrival = route.arrival?.name;
                      const { slug } = route.data;
                      return (
                        <Link
                          to={`/routes/${slug}/`}
                          className={s.link}
                          key={key}
                          children={`${t(departure)} - ${t(arrival)}`}
                        />
                      );
                    })
                  );
                }}
              </Query>
              <Link to="/all-routes/" className={s.link}>
                {t('all routes')}
              </Link>
            </div>
          </div>
        </div>
        <div className={s.item}>
          {t('destinations')}
          <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              <Query
                query={ORDER_WIDGET}
                variables={{
                  lang: language,
                  origin: 'popular-destinations',
                }}
              >
                {({ data = {}, loading }) => {
                  if (loading) return <Loading />;
                  const widget = data?.orderWidget;
                  const destinations = widget?.destinations;
                  return (
                    !!destinations?.length &&
                    destinations.map((destination, key) => {
                      const city = destination.city?.name;
                      const { slug } = destination.data;
                      return (
                        <Link
                          to={`/destinations/${slug}/`}
                          className={s.link}
                          key={key}
                          children={t(city)}
                        />
                      );
                    })
                  );
                }}
              </Query>
              <Link to="/all-destinations/" className={s.link}>
                {t('all destinations')}
              </Link>
            </div>
          </div>
        </div>
        <div className={s.item}>
          {t('for passengers')}
          <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              <Link to="/seats/" className={s.link}>
                {t('seating options')}
              </Link>
              {/* <div className={s.link} disabled={true}>
                {t('mobile app')}
                </div>
                <div className={s.link} disabled={true}>
                {t('30-day ticket')}
              </div> */}
              {/* <Link to="/info/" className={s.link}>
                {t('pins program')}
              </Link> */}
              <Link to="/discounts/" className={s.link}>
                {t('discounts')}
              </Link>
              <Link to="/ticket-sales-rules/" className={s.link}>
                {t('ticket sales rules')}
              </Link>
              <Link to="/transportation-rules/" className={s.link}>
                {t('transportation rules')}
              </Link>
              <Link to="/manage-booking/" className={s.link}>
                {t('manage booking')}
              </Link>
              {language === 'ru' && (
                <Link
                  to="/news/programma-loyalnosti-lux-express/"
                  className={s.link}
                >
                  {t('frequent traveler')}
                </Link>
              )}
              {language === 'en' && (
                <Link
                  to="/news/lux-express-customer-program/"
                  className={s.link}
                >
                  {t('frequent traveler')}
                </Link>
              )}
              {language === 'et' && (
                <Link
                  to="/news/lux-expressi-kliendiprogramm/"
                  className={s.link}
                >
                  {t('frequent traveler')}
                </Link>
              )}
              {language === 'lv' && (
                <Link
                  to="/news/lux-expressi-klientu-programma/"
                  className={s.link}
                >
                  {t('frequent traveler')}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={s.item}>
          {t('about us')}
          <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              <Link to="/blog/" className={s.link}>
                {t('blog')}
              </Link>
              <Link to="/about-us/" className={s.link}>
                {t('about lux express')}
              </Link>
              {/* <Link to="/career/" className={s.link}>
                {t('careers')}
              </Link> */}
            </div>
          </div>
        </div>
        <div className={s.item}>
          {t('info')}
          <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              <Link to="/contacts/" className={s.link}>
                {t('contacts')}
              </Link>
              {showFaq && (
                <Link to="/faq/" className={s.link}>
                  {t('guides and faq')}
                </Link>
              )}

              {/* <Link to="/customs-regulations/" className={s.link}>
                {t('customs regulations')}
              </Link> */}
              {/* <Link to="/lost-item/" className={s.link}>
                {t('report a lost item')}
              </Link> */}
              {/* <Link to="/ticket-refund/" className={s.link}>
                {t('return tickets')}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      {profileLoading ? (
        <div className={s.section}>
          <div className={s.item}>
            <Loading />
          </div>
        </div>
      ) : user.firstName ? (
        <div className={s.section}>
          <div className={s.item}>
            <div className={s.user}>
              {user.avatar ? (
                <div
                  className={s.avatar}
                  style={{ backgroundImage: `url(${user.avatar})` }}
                />
              ) : (
                <div className={s.avatar}>{user.firstName.slice(0, 2)}</div>
              )}
              <LazyLoadImage src="/images/svg/arrowBottom.svg" alt="" />
              <div className={s.wrapList}>
                <div className={s.list}>
                  <div className={s.wrap}>
                    <div className={s.triangle} />
                  </div>
                  {/* <Link to="/profile/customer-program/" className={s.link}>
                    {t('account')}
                  </Link> */}
                  <div
                    className={cx(s.link, s.logout)}
                    children={t('logout')}
                    onClick={logout}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null
      // <div onClick={toggleLoginModal}>{t('login')}</div>
      }

      <div className={s.section}>
        <div className={s.item}>
          {language}
          <div className={s.wrapList}>
            <div className={s.list}>
              <div className={s.wrap}>
                <div className={s.triangle} />
              </div>
              {langs
                .filter(lang => lang !== language)
                .map(lang => (
                  <div
                    key={lang}
                    className={s.link}
                    onClick={() => onChangeLang(lang)}
                    children={lang}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={s.section}>
        <div className={s.item}>{currencyName}</div>
      </div> */}
    </div>
  );
};

export default withTranslation()(DesktopHeader);
