/* eslint-disable */
import React, { useRef, useEffect, useState, useContext, memo } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { StoreContext } from '@lux/spa/store';
import { organizeStopsNew } from 'utils';
import { DEFAULT_LANG } from '@lux/spa/config';
import { JOURNEY_ROUTE } from '@lux/spa/apollo/queries';
import TicketLayout from './ticket-layout.jsx';
import s from './ticket.module.scss';

const Ticket = ({
  i18n,
  isMob,
  passengers,
  ticketLegs,
  className,
  transfers,
  journeyId,
  originStopName,
  destinationStopName,
  availableRegularSeats,
  availableBusinessClassSeats,
  regularPrice,
  businessClassPrice,
  durationTravel,
  departureDateTime,
  arrivalDateTime,
  availableCampaignSeats,
  availableBusinessCampaignSeats,
  history,
  notifications,
  isModifiableJourney = false,
  ticket,
  isSoldOut,
}) => {
  const currency = 'CURRENCY.RUB';
  const currencyName = 'RUB';
  const symbol = '₽';
  const { language } = i18n;
  const [state, dispatch] = useContext(StoreContext);
  const {
    passengersCount,
    returnDate,
    departDate,
    from,
    to,
    promocode,
  } = state.search;

  const {
    outbound,
    confirmPartnerBusNotice,
    selectedJourneyId,
  } = state.tickets;
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(false);
  const buttonGroupRef = useRef(null);

  const showTime = time => {
    return time
      .split('T')[1]
      .split(':')
      .slice(0, 2)
      .join(':');
  };

  const durationDays =
    durationTravel.includes('.') && durationTravel.split('.')[0];

  const duration = durationTravel
    .replace(`${durationDays}.`, '')
    .split(':')
    .slice(0, 2);

  const logoType =
    availableBusinessClassSeats || availableBusinessCampaignSeats
      ? 'logo-lounge'
      : 'logo-grey';

  let discountPrice = null;
  ticket.PriceClasses.forEach(i => {
    if (i.PriceClassName === 'Campaign') {
      discountPrice = true;
    }

    if (
      i.PriceClassName === 'BusinessCampaign' ||
      i.PriceClassName === 'CampaignBusiness'
    ) {
      discountPrice = true;
    }
  });

  const prices = [];
  ticket.PriceClasses.forEach(i => {
    prices.push(i.Price);
  });
  const minPrice = Math.min(...prices);

  let type = availableBusinessClassSeats ? 'lounge' : '';

  type =
    availableCampaignSeats || availableBusinessCampaignSeats ? 'promo' : type;

  const [getJorney, { loading, data }] = useLazyQuery(JOURNEY_ROUTE, {
    fetchPolicy: 'network-only',
    variables: {
      journeyId,
      lang: language,
      includeIntermediateStops: true,
    },
    onCompleted: data => {
      if (!data?.journeyRoute?.Legs) {
        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            isSearchOutOfDate: true,
          },
        });
        return;
      }

      const legs = data?.journeyRoute?.Legs || [];
      const stopsNew = organizeStopsNew(legs, showTime);
      if (stopsNew.length && selectedTicket) {
        const direction = outbound.selected ? 'inbound' : 'outbound';

        dispatch({
          type: 'UPDATE_TICKETS',
          payload: {
            ...(!outbound.selected
              ? {
                  passengersCount,
                }
              : {}),
            from,
            to,
            promocode,
            returnDate,
            departDate,
            lastSearch: history.location.search,
            [direction]: {
              businessClassPrice,
              regularPrice,
              selected: true,
              stops: stopsNew,
              id: journeyId,
              arrivalDateTime,
              departureDateTime,
              departDate: new Date(
                departureDateTime.split('T')[0].replace(/-/g, '/')
              ),
              type,
              departureCity: legs[0].DepartureCityName,
              departureTimezone: legs[0].DepartureTimezone,
              arrivalCity: legs[legs.length - 1].DestinationCityName,
            },
          },
        });

        if (
          (direction === 'outbound' && !returnDate) ||
          direction === 'inbound'
        ) {
          dispatch({
            type: 'UPDATE_TICKETS',
            payload: {
              showCurrencies: false,
            },
          });
          history.push(
            language === DEFAULT_LANG
              ? '/tickets/seat-details/'
              : `/${language}/tickets/seat-details/`
          );
        }

        if (direction === 'inbound') {
          history.push(
            language === DEFAULT_LANG
              ? '/tickets/seat-details/'
              : `/${language}/tickets/seat-details/`
          );
        }
      }
    },
  });

  const legs = data?.journeyRoute?.Legs || [];
  // const stops = organizeStops(legs, showTime);
  const stopsNew = organizeStopsNew(legs, showTime);
  const availableEquipment = ticketLegs
    ?.map(leg => leg.AvailableEquipment)
    ?.flat(1)
    ?.filter(
      (v, i, a) => a.findIndex(t => t.EquipmentType === v.EquipmentType) === i
    );

  const equipmentTypes = {
    ['COFFEE_MACHINE']: '/images/svg/hot-drinks.svg',
    ['WC']: '/images/svg/wc.svg',
    ['MULTIMEDIA_DISPLAY']: '/images/svg/multimedia.svg',
    ['INTERNET']: '/images/svg/wi-fi.svg',
    ['A_C']: '/images/svg/climate-control.svg',
    ['220V']: '/images/svg/220v-outlet.svg',
    ['COLD_DRINKS_MACHINE']: '/images/svg/free-bottle-of-water.svg',
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const classes = cn(s.ticket, type && s[type], className);

  const checkTicketPartnerBuses = () => {
    const withPartnersBuses = ticketLegs.some(leg => {
      return !leg.BrandName?.startsWith('Lux');
    });

    if (withPartnersBuses) {
      dispatch({
        type: 'UPDATE_TICKETS',
        payload: {
          isPartnerBusNotice: true,
          selectedJourneyId: ticket.JourneyId,
        },
      });
    } else {
      selectTicket();
    }
  };

  const selectTicket = () => {
    setSelectedTicket(true);

    let type =
      (availableCampaignSeats && 'Campaign') ||
      (availableBusinessCampaignSeats && 'CampaignBusiness') ||
      (availableRegularSeats && 'Regular') ||
      (availableBusinessClassSeats && 'Business');

    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        confirmPartnerBusNotice: false,
        ...(outbound.selected
          ? {
              inboundJourneyPriceClass: type,
            }
          : {
              outboundJourneyPriceClass: type,
            }),
      },
    });

    getJorney();
  };

  useEffect(() => {
    if (showDetails) {
      getJorney();
    }
  }, [showDetails]);

  useEffect(() => {
    const button = buttonGroupRef?.current;
    if (button) {
      button.addEventListener('mousemove', e => {
        button.style.setProperty(
          'background-position',
          `${e.clientX - 60}px ${e.clientY - 60}px`
        );
      });

      return () => {
        button.removeEventListener('mousemove', e => {
          button.style.setProperty(
            'background-position',
            `${e.clientX - 60}px ${e.clientY - 60}px`
          );
        });
      };
    }
  }, []);

  useEffect(() => {
    if (confirmPartnerBusNotice && selectedJourneyId === ticket.JourneyId)
      selectTicket();
  }, [confirmPartnerBusNotice, selectedJourneyId]);

  return (
    <TicketLayout
      isSoldOut={isSoldOut}
      classes={classes}
      type={type}
      isMob={isMob}
      showTime={showTime}
      departureDateTime={departureDateTime}
      arrivalDateTime={arrivalDateTime}
      logoType={logoType}
      originStopName={originStopName}
      destinationStopName={destinationStopName}
      durationDays={durationDays}
      duration={duration}
      transfers={transfers}
      toggleDetails={toggleDetails}
      showDetails={showDetails}
      loading={loading}
      stops={stopsNew}
      availableEquipment={availableEquipment}
      equipmentTypes={equipmentTypes}
      availableRegularSeats={availableRegularSeats}
      availableBusinessClassSeats={availableBusinessClassSeats}
      isModifiableJourney={isModifiableJourney}
      buttonGroupRef={buttonGroupRef}
      selectTicket={checkTicketPartnerBuses}
      minPrice={minPrice}
      symbol={symbol}
      legs={ticket.Legs}
      discountPrice={discountPrice}
      passengers={passengers}
      notifications={notifications}
    />
  );
};

export default withRouter(withTranslation()(memo(Ticket)));
